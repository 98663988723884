"use strict";
(function () {
	var app = angular.module("planer.patients", [])

		.controller("PatientsController", ["$scope", "planerApi", "$log", function($scope, planerApi, $log) {

			planerApi.get("patient", {}).then(function(data) {
				$scope.allPatients = data;
				$scope.filteredPatients = data;
			});

			$scope.searchText = "";
			$scope.searchPatients = function() {
				if ($scope.searchText.length >= 1) {
					$scope.filteredPatients = [];
					angular.forEach($scope.allPatients, function(value) {
						if (value.last_name.toLowerCase().lastIndexOf($scope.searchText.toLowerCase(), 0) === 0) {
							$scope.filteredPatients.push(value);
						}
					});
				} else {
					$scope.filteredPatients = $scope.allPatients;
				}
			};

			$scope.getPatientGender = function(status) {
				if ($scope.selectedPatient) {
					switch ($scope.selectedPatient.sex) {
						case "m":
							return "fa-mars";
							break;

						case "f":
							return "fa-venus";
							break;

						default:
						case "u":
							return "fa-genderless";
							break;
					}
				} else {
					return "fa-genderless";
				}

			};

			$scope.selectedPatient = null;
			$scope.hasMoreComments = false;
			$scope.selectPatient = function(id) {
				$scope.selectedTherapy = null;
				angular.forEach($scope.allPatients, function(value) {
					if (value.id === id) {
						$scope.selectedPatient = value;
						$scope.hasMoreComments = Object.keys($scope.selectedPatient.comments).length > 3;
						return;
					}
				});
			};

			$scope.isActivePatient = function(id) {
				if ($scope.selectedPatient && $scope.selectedPatient.id == id) {
					return "active";
				} else {
					return "";
				}
			};

			$scope.selectedTherapy = null;
			$scope.selectTherapy = function(id) {
				angular.forEach($scope.selectedPatient.therapies, function(value) {
					if (value.id === id) {
						$scope.selectedTherapy = value;
						return;
					}
				});
			};

			$scope.isActiveTherapy = function(id) {
				if ($scope.selectedTherapy && $scope.selectedTherapy.id == id) {
					return "active";
				} else {
					return "";
				}
			};


		}]);
}());