"use strict";
(function () {
	var app = angular.module("planer.auth", ['satellizer', 'ngIdle', 'ui.bootstrap', 'angularMoment'])

		.config(function(IdleProvider, KeepaliveProvider) {
			IdleProvider.idle(600);
			IdleProvider.timeout(30);
			KeepaliveProvider.interval(10);
		})

		.run(['Idle', '$auth', 'moment', '$log', '$http', 'appConfig', '$rootScope', function(Idle, $auth, moment, $log, $http, appConfig, $rootScope) {
			// The user reloaded the page and is still authenticated.
			if ($auth.isAuthenticated()) {

				// We create the currentUser Variable from localStorage so we can use it again
				$rootScope.currentUser = JSON.parse(localStorage.getItem('user'));

				// We refresh the token and start the idle again
				if (!Idle.running()) {
					$http({
						method : "post",
						url    : appConfig.apiUrl + "auth/refresh",
					}).then(function (response) {
						$auth.setToken(response.headers("Authorization").replace("Bearer ", ""));
					});

					Idle.watch();
				}
			}
		}])

		.controller("AuthController", ["$scope", "$auth", "$state", "Idle", "Keepalive", "$uibModal", "$rootScope", "$http", "$log", function($scope, $auth, $state, Idle, Keepalive, $uibModal, $rootScope, $http, $log) {
			$scope.hasError = false;
			$scope.error = "";
			$scope.currentUser = $rootScope.currentUser;


			$scope.authenticated = function() {
				return $auth.isAuthenticated();
			};

			$scope.isAdmin = function () {
				if ($rootScope.currentUser) {
					return $rootScope.currentUser.roles[0].id == 1;
				}
				return false;
			};



			$scope.login = function() {

				var credentials = {
					name: $scope.name,
					password: $scope.password
				};

				// Use Satellizer's $auth service to login
				$auth.login(credentials).then(function() { return $http.get('api/auth/user');})
					.catch(function(response) { if (response.status == "401") { $scope.hasError = true; $scope.error = "Anmeldedaten sind nicht korrekt." }})
					.then(function(response) {
						var user = JSON.stringify(response.data.user);

						// Set the stringified user data into local storage
						localStorage.setItem('user', user);

						// Set currentUser in $rootScope to make it available everywhere in the app
						$rootScope.currentUser = response.data.user;

						// Idle start
						Idle.watch();

						// If login is successful, redirect to the users state
						$state.go('stMain', {});
					});
			};

			$scope.logout = function() {
				$auth.logout().then(function(data) {

					localStorage.removeItem('user');

					$rootScope.currentUser = null;

					// Idle stop
					Idle.unwatch();

					// Go back to login
					$state.go('stAuth', {});
				});


			};

			// NavBar
			$scope.navIsClosed = true;
			$scope.navBar_class = function () {
				if ($scope.navIsClosed == true) {
					return '';
				} else {
					return 'navbar-opened';
				}
			};
			$scope.hamburger_class = function() {
				if ($scope.navIsClosed == true) {
					return 'is-closed';
				} else {
					return 'is-open';
				}
			};




			// The user was idle for the configured time. We trigger the timeout
			$scope.$on('IdleStart', function() {
				closeModals();

				$scope.warning = $uibModal.open({
					templateUrl: 'tpl/modal/idle.php',
					windowClass: 'modal-danger'
				});
			});

			// The user interrupted the timeout and is working again
			$scope.$on('IdleEnd', function() {
				closeModals();
			});

			// The user has not shown any activity so we go to the logout
			$scope.$on('IdleTimeout', function() {
				closeModals();
				$scope.logout();
			});

			// Helper function for ngidle
			function closeModals() {
				if ($scope.warning) {
					$scope.warning.close();
					$scope.warning = null;
				}

				if ($scope.timedout) {
					$scope.timedout.close();
					$scope.timedout = null;
				}
			}

		}]);

}());