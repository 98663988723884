"use strict";
(function () {

	var app = angular.module('planer.api', ['angular-jwt', 'ngCookies'])

		.factory("planerApi", function($http, $auth, $cookies, $log, appConfig, jwtHelper) {

			var refreshToken = function (config) {
				return apiRawCall({
					method : "post",
					url: appConfig.apiUrl + "auth/refresh"
				}).then(function(response){
					$auth.setToken(response.headers("Authorization").replace("Bearer ", ""));
					return apiRawCall(config)
						.then(apiCallback);
				});
			};

			/**
			 * The Api Call
			 */
			var apiCall = function (method, endpoint, params, post) {

				if (typeof params === "undefined") {
					params = {};
				}
				var config = {
					method : method,
					url: appConfig.apiUrl + endpoint,
					params: params,
					data: post
				};

				if (jwtHelper.isTokenExpired($auth.getToken())) {
					return refreshToken(config);
				} else {
					return apiRawCall(config)
						.then(apiCallback);
				}

			};


			var apiRawCall = function (config) {
				return $http(config);
			};

			var apiCallback = function(response) {
				return response.data;
			};

			/* RAW PUBLIC FUNCTIONS
			 ************************************************************************************ */

			var get = function(model, params) {
				return apiCall("get", model, params, null);
			};

			var getByIndex = function(model, id, params) {
				return apiCall("get", model + "/" + id, params, null);
			};

			var postInsert = function(model, params, data) {
				return apiCall("post", model + "/insert", params, data);
			};

			var postUpdate = function(model, id, params, data) {
				return apiCall("post", model + "/" + id, params, data);
			};

			var deleteDestroy = function(model, id, params) {
				return apiCall("delete", model + "/" + id, params, null);
			};

			/* CONVENIENCE PUBLIC FUNCTIONS
			 ************************************************************************************ */

			var getLoggedInUser = function () {
				return apiCall("get", "auth/user", {}, null);
			};


			/* EXPOSE API
			 ************************************************************************************ */
			return {
				get: get,
				index: getByIndex,
				insert: postInsert,
				update: postUpdate,
				destroy: deleteDestroy,

				getLoggedInUser: getLoggedInUser
			};

		})

}());