"use strict";
(function () {

	var app = angular.module("planer.main", ['ui.calendar', 'ui.bootstrap', 'moment-picker'])

		.controller("MainController", ["$scope", "uiCalendarConfig", "$uibModal", "$log", function($scope, uiCalendarConfig, $uibModal, $log) {

			$scope.newAppointment = null;


			$scope.closeModal = function() {
				if ($scope.newAppointment) {
					$scope.newAppointment.close();
					$scope.newAppointment = null;
				}
			};

			$scope.getPatients = function() {

			};

			$scope.patientAutoComplete = function() {

			};


			// Event: fires on clicking an empty day
			$scope.dayClick = function( date, jsEvent, view, resourceObj ){
				$scope.newAppointmentDate = date.format("L LT");
				$scope.newAppointment = $uibModal.open({
					templateUrl: 'tpl/modal/new_appointment.php',
					windowClass: 'modal-default',
					scope: $scope,
					backdrop: 'static',
					keyboard: false
				});
			};

			// Event: fires on moving an item
			$scope.eventMove = function(event, delta, allDay, revertFunc, jsEvent, ui, view){

			};

			// Event: fires on resizing an item
			$scope.eventOnResize = function(event, delta, revertFunc, jsEvent, ui, view ){

			};

			// Event: fires on dropping an item on the calendar
			$scope.externalDrop = function(date, jsEvent, ui, resourceId) {

			};

			// Event: fires on clicking on an existing event
			$scope.eventClick = function(event){

			};

			// Event: fires on date change
			$scope.renderView = function(view){

			};

			// Event: fires on view change
			$scope.changeView = function(view, calendar) {

			};

			$scope.nextDayClick = function() {
				var tempDay = uiCalendarConfig.calendars['calendar-agenda'].fullCalendar('getDate');
				if (tempDay.day() === 5) {
					tempDay = tempDay.add(3, "d");
				} else {
					tempDay = tempDay.add(1, "d");
				}
				$scope.dt = tempDay;
				uiCalendarConfig.calendars['calendar-agenda'].fullCalendar('gotoDate', $scope.dt);
				uiCalendarConfig.calendars['calendar-agenda'].fullCalendar('removeEvents');
			};

			$scope.prevDayClick = function() {
				var tempDay = uiCalendarConfig.calendars['calendar-agenda'].fullCalendar('getDate');
				if (tempDay.day() === 1) {
					tempDay = tempDay.subtract(3, "d");
				} else {
					tempDay = tempDay.subtract(1, "d");
				}
				$scope.dt = tempDay;
				uiCalendarConfig.calendars['calendar-agenda'].fullCalendar('gotoDate', $scope.dt);
				uiCalendarConfig.calendars['calendar-agenda'].fullCalendar('removeEvents');
			};

			$scope.selectDate = function() {
				uiCalendarConfig.calendars['calendar-agenda'].fullCalendar('gotoDate', $scope.dt);
			};

			$scope.uiConfig = {
				calendar: {
					height: "auto",
					contentHeight: "auto",
					editable: true,
					droppable: true,
					header: {
						left: 'prevDayButton',
						center: 'title',
						right: 'nextDayButton'
					},
					customButtons: {
						nextDayButton: {
							icon: 'right-single-arrow',
							click: $scope.nextDayClick
						},
						prevDayButton: {
							icon: 'left-single-arrow',
							click: $scope.prevDayClick
						}
					},
					defaultView: 'agendaDay',
					views: {
						agendaDay: {
							minTime: "06:00:00",
							maxTime: "21:00:00",
							allDaySlot: false,
							titleFormat: 'DD. MMMM'
						}
					},
					nowIndicator: true,
					timezone: "Europe/Berlin",

					eventsources: $scope.eventSources,

					dayClick: $scope.dayClick,
					eventDrop: $scope.eventMove,
					eventResize: $scope.eventOnResize,
					eventClick: $scope.eventClick,
					viewRender: $scope.renderView,
					drop: $scope.externalDrop,
				}
			};

			$scope.dt = new Date();

			$scope.shownMonth = $scope.dt.getMonth();
			$scope.shownYear = $scope.dt.getYear();

			$scope.dateOptions = {
				showWeeks: true,
				startingDay: 1,
				dateDisabled: function (data) {
					var date = data.date,
						mode = data.mode;
					return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
				},

				customClass: function (data) {
					var date = new Date(data.date),
						mode = data.mode,
						activeDate = data.activeDate;

					$scope.shownMonth = activeDate.getMonth();
					$scope.shownYear = activeDate.getFullYear();

					var classes = [];

					if (mode === 'day' && $scope.shownMonth != date.getMonth()) {
						classes.push("not-this-month");
					}

					if (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6)) {
						classes.push("weekend");
					}

					return classes.join(" ");
				}
			};

		}])

}());