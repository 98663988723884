"use strict";
(function () {
	"use strict";
	(function () {

		var app = angular.module("planer.layout", [])

			.controller("LayoutController", function($scope) {

			})

	}());
}());