"use strict";
(function () {
	var app = angular.module('planer', [
		'ui.router',
		'angularMoment',

		'planer.api',

		'planer.auth',
		'planer.layout',

		'planer.admin',
		'planer.main',
		'planer.settings',
		'planer.weekplan',
		'planer.patients'
	])

		.run(["amMoment", "$auth", "$state", "$rootScope", function(amMoment, $auth, $state, $rootScope) {
			amMoment.changeLocale('de');

			$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){
				// prevent access to unauthorized routes
				if (toState.authenticate && !$auth.isAuthenticated()){
					event.preventDefault();
					return $state.go('stAuth');
				}

				if (toState.admin){
					if ($rootScope.currentUser && $rootScope.currentUser.roles[0].id != 1) {
						event.preventDefault();
						return $state.go('stMain');
					}
				}
			});

		}])

		.constant("appConfig", {
			appName: "Adjutor",
			apiUrl: "http://planer.shrimpanse.de/api/"
		})

		.config(function($stateProvider, $urlRouterProvider, $authProvider) {

			$authProvider.loginUrl = '/api/auth/authenticate';

			$urlRouterProvider.otherwise("/");

			$stateProvider
				.state('stMain', {
					url: "/",
					templateUrl: "tpl/sites/main.php",
					controller: 'MainController as main',
					authenticate: true,
					onEnter: function(){
					},
					onExit: function(){
					}
				})
				.state('stWeekplan', {
					url: "/weekplan",
					templateUrl: "tpl/sites/weekplan.php",
					controller: 'SettingsController as settings',
					authenticate: true,
					onEnter: function(){
					},
					onExit: function(){
					}
				})
				.state('stPatients', {
					url: "/patients",
					templateUrl: "tpl/sites/patients.php",
					controller: 'PatientsController as patients',
					authenticate: true,
					onEnter: function(){
					},
					onExit: function(){
					}
				})
				.state('stSettings', {
					url: "/settings",
					templateUrl: "tpl/sites/settings.php",
					controller: 'SettingsController as settings',
					authenticate: true,
					onEnter: function(){
					},
					onExit: function(){
					}
				})
				.state('stAuth', {
					url: "/auth",
					templateUrl: "tpl/sites/auth.php",
					controller: 'AuthController as auth',
					onEnter: function(){
					},
					onExit: function(){
					}
				})
				.state('stAdmin', {
					url: "/admin",
					templateUrl: "tpl/sites/admin.php",
					controller: 'AdminController as admin',
					authenticate: true,
					admin: true,
					onEnter: function(){
					},
					onExit: function(){
					}
				})
		})

		.directive("planerNavbar", function() {
			return {
				restrict: "E",
				templateUrl: "tpl/layout/navbar.php",
				controller: "AuthController as auth",
				replace: true
			}
		})

		.directive("planerContent", function() {
			return {
				restrict: "E",
				templateUrl: "tpl/layout/content.php",
				controller: "LayoutController as layout",
				replace: true
			}
		});

}());

